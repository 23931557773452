#readStoryTopContainer {
  /* width: 100%; */
  /* background-color: #ededed; */
  /* box-shadow: 0 0 10px rgba(0,0,0,.2);   */
  z-index:4;
  /* transition: width .3s ease; */
}

.hoverableWordSpan {
  cursor: default;
  padding-left: 1px;
  padding-right: 1px;
  margin-left: -1px;
  margin-right: -1px;
  color: #191c21;
}
.hoverableWordSpan:hover {
  cursor: default;
  background-color: #82ca1c !important;
  color: #fff;

}

/* ONBOARDING */

.onboardingWord {
  animation-name: color;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes color {
  0% {
    background-color: #82ca1c50;
    color: #8a94a6;
  }
  50% {
    background-color: #82ca1c;
    color: white;
  }
  100% {
    background-color: #82ca1c50;
    color: #8a94a6;
  }
}

/* .onboardingWord {
  background-color: red;
  border: 1px solid red;
}

.markdownParagraphWrapper, p{
  margin-bottom: 5px;
} */

 /* .popover {
  background-color: #82ca1c !important;
  color: #82ca1c;
} */

#ccAttrText{
  color: white;
  opacity: .4;
}
#ccAttrText>a {
  color: white;
  text-decoration: underline;
}


progress::-moz-progress-bar {
  background-color: #f07300 !important;
}
/* progress::-moz-progress-value {
  background-color: #82ca1c !important;
} */
progress::-webkit-progress-bar {
  background-color: #d4d4d4 !important;
}
progress::-webkit-progress-value {
  background-color: #f07300 !important;
  transition: .02s;
  border-radius: 10px;
}
progress[value]::-webkit-progress-value {
  transition: 0.1s;
}

.lightbutton {
  background-color: #F5FAFF;
  transition: .02s;
  cursor: pointer;
}
.lightbutton:hover {
  background-color: #edf3f9;
  transition: .02s;
}

.lightButtonDark {
  background-color: rgb(215, 229, 243);
  transition: .02s;
  cursor: pointer;
}
.lightButtonDark:hover {
  background-color: rgba(215, 229, 243, .85);
  transition: .02s;
}


/* ----MUSIC BOX----- */

.music-box {
  width: 100%;
  z-index: 2;
  background-color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 3px;
}
.music-box .album {
  position: relative;
}
.music-box .album .photo {
  background-size: cover;
  width: 100%;
  height: 300px;
}
.music-box .album .infos {
  background-color: rgba(70, 50, 57, 0.3);
  width: 100%;
  height: 60px;
  position: absolute;
  bottom: 0;
}
.music-box .album .infos .song {
  padding: 10px;
  color: #82ca1c;
  letter-spacing: 0.5px;
}
.music-box .album .infos .song span, .music-box .album .infos .song small {
  display: block;
}
.music-box .dashboard {
  margin-top: 15px;
}
.music-box .dashboard .list {
  position: relative;
  transition-duration: 0.3s;
  background-color: #eee;
  right: 0;
}
.music-box .dashboard .list .list-btn {
  z-index: 2;
  background-color: #82ca1c;
  width: 44px;
  height: 44px;
  position: absolute;
  right: 5px;
  top: -44px;
  cursor: pointer;
  transition-duration: 0.3s;
  transition-delay: 0.9s;
}
.music-box .dashboard .list .list-btn span {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 28px;
  height: 1px;
  background-color: #82ca1c;
  position: relative;
  transition: width 0.3s 0.3s, background-color 0.3s 0.6s;
  transition-timing-function: linear;
}
.music-box .dashboard .list .list-btn span::before, .music-box .dashboard .list .list-btn span::after {
  background-color: #82ca1c;
  content: "";
  width: 28px;
  height: 1px;
  position: absolute;
  left: 0;
  top: 0;
  transition-timing-function: linear;
  transition: width 0.3s 0.3s, margin 0.3s 0.6s, transform 0.3s 0.3s;
}
.music-box .dashboard .list .list-btn span::before {
  margin-top: 6px;
}
.music-box .dashboard .list .list-btn span::after {
  margin-top: -6px;
}
.music-box .dashboard .list.active .list-btn {
  top: -322px;
  transition: top 0.3s 0.6s;
}
.music-box .dashboard .list.active .list-btn span {
  background-color: transparent;
  width: 34px;
  transition: width 0.3s, background-color 0.3s;
}
.music-box .dashboard .list.active .list-btn span::before, .music-box .dashboard .list.active .list-btn span::after {
  margin: 0;
  width: 34px;
  transition: width 0.3s, margin 0.3s, transform 0.3s 0.3s;
}
.music-box .dashboard .list.active .list-btn span::before {
  transform: rotate(225deg);
}
.music-box .dashboard .list.active .list-btn span::after {
  transform: rotate(315deg);
}
.music-box .dashboard .player .time {
  color: #000;
  font-size: 16px;
}
.music-box .dashboard .player .time-rail {
  height: 1px;
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
  overflow: hidden;
}
.music-box .dashboard .player .time-rail .thumb, .music-box .dashboard .player .time-rail .track {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  /* border-radius: 3px; */
  /* cursor: pointer; */
}
.music-box .dashboard .player .time-rail .thumb {
  width: 0px;
  height: 0px;
  /* border-radius: 50%; */
  background-color: #82ca1c;
  z-index: 1;
  transition: .7s;
}
.music-box .dashboard .player .time-rail .thumb:hover {
  /* transform: translateY(-50%) scale(1.2); */
}
.music-box .dashboard .player .time-rail .thumb::before {
  content: "";
  width: 3000px;
  height: 8px;
  /* border-radius: 5px; */
  background-color: #82ca1c;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.music-box .dashboard .player .time-rail .track {
  top: 50%;
  width: 100%;
  height: 8px;
  /* border-radius: 5px; */
  background-color: #e7e7e7;
}
.music-box .dashboard .player .time-rail input[type="range"] {
  width: 100%;
  -webkit-appearance: none;
  overflow: hidden;
  background: none;
}
.music-box .dashboard .player .time-rail input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #82ca1c;
}
.music-box .dashboard .action-button {
  text-align: center;
  padding-top: 5px;
}
.music-box .dashboard .action-button a {
  cursor: pointer;
  display: inline-block;
  /* width: 40px;
  height: 40px; */
  margin: 3px;
  margin-right: 10px;
  margin-left: 10px;
  line-height: 55px;
  color: #82ca1c;
  transition-duration: 0.3s;
}
.music-box .dashboard .action-button a:hover {
  color: #82ca1c;
}

.music-box .dashboard .action-button a.active {
  color: #82ca1c;
}
.music-box .dashboard .action-button a .music-side-button {
  font-size: 1.5em;
  /* color: blue; */
}
.music-box .lists {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #463239;
  transform: translateX(-100%);
  transition: 0.3s 0s;
}
.music-box .lists.active {
  transform: translateX(0);
  transition: 0.3s 0.9s;
}
.music-box .lists .label {
  font-size: 2em;
  padding: 8px 15px;
  height: 60px;
  box-sizing: border-box;
  color: #82ca1c;
}
.music-box .lists ul {
  padding-left: 0;
  margin: 0;
  list-style-type: none;
  width: 100%;
  height: calc(100% - 60px);
  position: absolute;
  overflow-y: auto;
  background-color: #c6ad94;
}
.music-box .lists ul::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}
.music-box .lists ul::-webkit-scrollbar-track {
  background-color: #463239;
}
.music-box .lists ul::-webkit-scrollbar-thumb {
  background-color: #000;
  border-radius: 3px;
}
.music-box .lists ul li {
  cursor: pointer;
  padding: 5px 15px;
  transition-duration: 0.3s;
}
.music-box .lists ul li span, .music-box .lists ul li em {
  display: inline-block;
}
.music-box .lists ul li span small {
  display: block;
}
.music-box .lists ul li em {
  float: right;
  top: 0;
  font-style: normal;
  font-size: 12px;
  margin-top: 12px;
}
.music-box .lists ul li:nth-of-type(odd) {
  background-color: rgba(70, 50, 57, 0.1);
}
.music-box .lists ul li:hover {
  background-color: rgba(70, 50, 57, 0.3);
}
