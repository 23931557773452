/* BOOTSTRAP OVERRIDES */
.btn-primary {
  background-color: #82ca1c;
  border-color: #82ca1c;
}

.btn-primary:hover {
  background-color: #73b31b;
  border-color: #73b31b;
}

.btn {
  border-radius: 0px;
}
.text-primary {
  color: #82ca1c;
}

.popover{
  /* background-color: #82ca1c; */
}

* {
  font-family: 'CircularStd', 'Calibri';
}

.html::-webkit-scrollbar { width: 0 !important }
#wordsScroll::-webkit-scrollbar { width: 0 !important }

body, html{
  margin: 0;
  padding: 0;
  font-variant: normal !important;
  font-feature-settings: normal !important;
}



#formcard {
	max-width:450px;
	background-color: #f7f7f7;
	padding:5px;
	border-radius:5px;
	box-shadow: 0px 0px 22px .1px #464646;
}

.lightText {
	color: #f7f7f7;
}

.highlight-translation {
  background-color: #82ca1c;
  color: white;
  border-radius: 2px;
}

.sidebar-nav select, .browseDropdowns select{
  -webkit-appearance: none;
  appearance: none;
}


.box-shadow { box-shadow: 0 0 11px rgba(0, 0, 0, .07); }

.storycard{
  overflow: hidden;
  margin: auto;
  border-radius: 12px;
  box-shadow: 0 3px 4px 0 rgba(10, 31, 68, 0.1), 0 0 1px 0 rgba(10, 31, 68, 0.08);
  background-color: white;
  min-height: 300px;
  position: relative;
  /* transition: .2s; */
}
.storycard:hover {
  box-shadow: 0 3px 4px 0 rgba(10, 31, 68, 0.2), 0 0 1px 0 rgba(10, 31, 68, 0.15);
  /* transition: .2s; */
}

/* ANIMATIONS */

.fadeanim {
  -webkit-transition: .6s;
  -moz-transition: .6s;
  -o-transition: .6s;
  transition: .6s;
  opacity: 0;
  /* visibility: hidden;  */
}
.fadeanim.show {
  opacity: 1;
  /* visibility: visible;  */
}

.fadeMeIn {
  animation:fadeIn .15s linear;
}

@keyframes fadeIn {
  0% {
    opacity:0
  }
  100% {
    opacity:1;
  }
}

.autoTransition {
  transition: transform 1s ease-in-out;
}

.fadeHover {
  opacity: 1;
  transition: .1s;
  /* -webkit-transition: opacity 0.2s ease;
  -moz-transition: opacity 0.2s ease;
  -o-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease; */
}

.fadeHover:hover {
  opacity: .85;
  transition: .1s;
  cursor: pointer;
  /* -webkit-transition: opacity 0.2s ease;
  -moz-transition: opacity 0.2s ease;
  -o-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease; */
}

.fadeHoverReverse {
  opacity: .6;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.fadeHoverReverse:hover {
  opacity: 1;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}


.shadowHover {
  box-shadow: none;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.shadowHover:hover {
  box-shadow: 0 0 12px rgba(0,0,0,.12);
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

#coloredSelection *::selection {
  background-color: #82ca1c;
  color: white;
}

.ant-message {
  z-index: 9999 !important;
}

.collapse-row-open {
  overflow: hidden;
  transition: .2s;
  max-height: 300px;
}

.collapse-row-closed {
  overflow: hidden;
  transition: .2s;
  /* height: 0px; */
  max-height: 0px;
}

.markCompleteButton {
  transition: .1s;
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  height: 40px;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.markCompleteButtonComplete {
  color: grey;
  border-color: grey;
}

.markCompleteButtonIncomplete {
  color: #0080ff;
  border-color: #0080ff;
}

.markCompleteButtonComplete.canHover:hover {
  transition: .1s;
  background-color: grey;
  color: #ffffff;
  cursor: pointer;
}

.markCompleteButtonIncomplete.canHover:hover {
  transition: .1s;
  background-color: #0080ff;
  color: #ffffff;
  cursor: pointer;
}

.markdownCourseDesc {
  width: 640px;
}

.markdownCourseDescMobile {
  width: 100%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.hoverLink {
  text-decoration: none;
  cursor: pointer;
  color: #1a0dab;
}

.hoverLink:hover {
  text-decoration: underline;
  cursor: pointer;
}

.playButtonActive {
  opacity: 1;
}

.playButtonSecondary {
  opacity: .4;
  transition: .1s;
}
.playButtonSecondary:hover {
  opacity: 1;
  transition: .1s;
}

.alreadySavedWord {
  background-color: #ffff94;
}