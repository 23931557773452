.articleContainer {
  background-color: #fafafa;
}
.articleContainer:hover {
  background-color: #00bcff15;

}

@keyframes heightAnim {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}


.animateHeight {
  /* transition: background 0.2s ease-in-out; */
  animation: heightAnim .3s ease-in-out;
}

.adminArticle {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border-left: 0px solid #191919;
}

.adminArticle:hover {
  border-left: 3px solid #191919;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.markdownButton {
  border: 1px solid #dddddd;
}
.markdownButton:hover {
  border: 1px solid grey;
}
