/*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/template-overviews/simple-sidebar)
 * Copyright 2013-2017 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-simple-sidebar/blob/master/LICENSE)
 */

 body {
  overflow-x: hidden;
}

#sideNavWrapper {
  min-height: 100vh;
  padding-left: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#sideNavWrapper.toggled {
  padding-left: 250px;
}

#sidebar-wrapper {
  z-index: 3;
  position: fixed;
  left: 250px;
  width: 0;
  height: 100%;
  margin-left: -250px;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  /* box-shadow: 0 0 14px rgba(0,0,0,.3); */
}

#sideNavWrapper.toggled #sidebar-wrapper {
  width: 250px;
}

#page-content-wrapper {
  width: 100%;
  min-height: 100vh;
  position: absolute;
  /* padding: 15px; */
}

#sideNavWrapper.toggled #page-content-wrapper {
  position: absolute;
  min-height: 100vh;
  margin-right: -250px;
}


.sidebar-nav {
  position: absolute;
  top: 0;
  width: 250px;
  margin: 0;
  padding: 0;
  padding-top: 65px;
  list-style: none;
}

.sidebar-nav li {
  text-indent: 20px;
  line-height: 40px;
}

.sidebar-nav li a {
  display: block;
  text-decoration: none;
  color: #999999;
}

.sidebar-nav li a:hover {
  text-decoration: none;
}

.sidebar-nav li a:active, .sidebar-nav li a:focus {
  text-decoration: none;
}

.sidebar-nav>.sidebar-brand {
  height: 65px;
  font-size: 18px;
  line-height: 60px;
}

.sidebar-nav>.sidebar-brand a {
  color: #999999;
}

.sidebar-nav>.sidebar-brand a:hover {
  color: #fff;
  background: none;
}

@media(min-width:768px) {
  #sideNavWrapper {
    padding-left: 77px;
  }
  #sideNavWrapper.toggled {
    padding-left: 250px;
  }
  #sidebar-wrapper {
    width: 77px;
  }
  #sideNavWrapper.toggled #sidebar-wrapper {
    width: 250px;
  }
  #page-content-wrapper {
    /* padding: 20px; */
    position: relative;
    min-height: 100vh;
  }
  #sideNavWrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
    min-height: 100vh;
  }
}
