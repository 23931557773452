
#flashCard {
  /* background-image: url('../img/paper.png'); */
}

.activeWord {
  background-color: #fcfcfd;
  color: #0d55cf;
}

.inactiveWord{
  color: #8a94a6;
}